@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.btn {
  border: 1px solid rgba(52, 182, 101, 255);
  font-size: 15px;
  color: rgba(52, 182, 101, 255);
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.btn-simple {
  border: 1px solid;
  font-size: 15px;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.btn-primary {
  background-color: rgba(52, 182, 101, 255);
  color: white;
}

.text-raw {
  font-size: var(--base-font-size);
}
